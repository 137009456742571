import LoadingButton from "@mui/lab/LoadingButton";
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import Label from 'components/dist/atoms/Label';
import Text from 'components/dist/atoms/Text';
import { FormikHelpers, useFormik } from 'formik';
import { useContext } from "react";
import NextLinkComposed from "src/components/common/next-link-composed";
import { Route } from 'src/constants/ui';
import { AuthContext } from "src/contexts/auth-context";
import { useSubdomainLender } from "src/contexts/subdomain-lender-context";
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import { ForgotPasswordRequest } from "src/types/auth";
import { toast } from "src/utils/toast";

import { CompanyLogo } from "../company-logo";
import { requestPasswordResetFormValidationSchema } from "./request-password-reset-form-validation";

interface Props {
    onSuccess?: (values: ForgotPasswordRequest) => void;
    onBack?: () => void;
    showLogo?: boolean;
    showCancelLink?: boolean;
}

const RequestPasswordResetForm = (props: Props) => {
    const { onSuccess, showLogo = true, showCancelLink = true } = props;
    const { forgotPassword } = useContext(AuthContext);
    const { logo, name } = useSubdomainLender();
    const formik = useFormik({
        validationSchema: requestPasswordResetFormValidationSchema,
        initialValues: {
            username: ''
        },
        onSubmit: async (values: ForgotPasswordRequest, formikHelpers: FormikHelpers<ForgotPasswordRequest>) => {
            formikHelpers.setSubmitting(true);
            try {
                values.username = values.username.trim();
                const lResponse = await forgotPassword(values);
                if (lResponse.success) {
                    toast({
                        type: 'success',
                        content: 'Email sent to ' + values.username + '. If you do not receive an email, please check your spelling and try again.'
                    })
                    formikHelpers.setSubmitting(false);
                    onSuccess?.(values);
                } else {
                    toast({
                        type: 'info',
                        content: lResponse.message
                    });
                    formikHelpers.setSubmitting(false);

                }
            } catch (error) {
                formikHelpers.setSubmitting(false);
            }
        },
    });

    return (<form
        className="w-full text-center flex flex-col"
        noValidate
        onSubmit={formik.handleSubmit}>
        <div className="gap-6 flex flex-col">
            {showLogo && (<CompanyLogo name={name} logo={logo} />)}
            <Text size="xl" weight="medium">
                Forgot your password?
            </Text>
        </div>
        <Text className="mt-3" size="sm">
            Please enter your email. If it is associated with your account you will receive reset instructions
        </Text>
        <Label
            required
            variant="secondary"
            htmlFor="username" className="text-left mt-12">
            Email Address
        </Label>
        <Input
            wrapClassName="mt-2"
            id='username'
            name='username'
            data-testid='username'
            autoComplete='username'
            placeholder="Email Address"
            autoFocus
            onChange={formik.handleChange}
            value={formik.values.username}
            error={formik.touched.username && Boolean(formik.errors.username)}
        />
        {formik.touched.username && formik.errors.username && (<Text
            size="sm"
            variant="destructive"
            className="text-left flex gap-1 items-center mt-1">
            <Icon name="InfoEmpty" width={14} height={14} />  {formik.errors.username}
        </Text>)}
        <LoadingButton
            sx={{
                minHeight: 48,
                fontSize: 18,
                fontWeight: 500,
                mt: 3,
                backgroundColor: themeV2.colors.blue[100],
                '&:disabled': {
                    backgroundColor: themeV2.colors.blue[60],
                },
                '&:hover': {
                    backgroundColor: themeV2.colors.blue[60],
                }
            }}
            fullWidth
            data-testid='login-button'
            loading={formik.isSubmitting}
            type='submit'
            color='success'
            variant='contained'>
            Continue
        </LoadingButton>
        {showCancelLink && (
            <NextLinkComposed href={Route.LOGIN} passHref>
                <Text
                    variant="blue"
                    underline
                    className="underline-offset-4 cursor-pointer font-medium text-center mt-8">
                    Cancel                 
                </Text>
            </NextLinkComposed>
        )}
    </form>
    )
};

export default RequestPasswordResetForm;

