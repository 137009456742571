import { yupPasswordValidation } from 'src/utils/yup-validation';
import * as Yup from 'yup';

export const createNewPasswordFormSchema = Yup
    .object()
    .shape({
        username: Yup
            .string()
            .min(3, 'Must be at least 3 characters')
            .max(255)
            .required('Required'),
        confirmationCode: Yup
            .string()
            .max(255)
            .required('Required Field'),
        password: yupPasswordValidation,
        passwordConfirmation: Yup
            .string()
            .required('Required field')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    })
