import { j as t } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as a, useState as n } from "react";
import "./Accordion-B9fcsvgO.js";
import "./Avatar-D2CKq5wD.js";
import "./Badge-C0bj4DFU.js";
import { B as d } from "./Button-DhTfdjdw.js";
import "./DropdownMenu-DyFHoD22.js";
import { I as f } from "./Icon-GJXsxHcP.js";
import { I as r } from "./Input-vl7Xx2ol.js";
import "./Label-BKL8LTmX.js";
import "./Text-DOFuHqUo.js";
import "./Tooltip-ae2E5kx6.js";
import "./Stack-9Jnn74Nm.js";
import "./Switch-87ODchm_.js";
import "./Toast-B2BLNDE3.js";
import "./Toggle-DH_2vm0p.js";
const h = a((s, i) => {
  const [o, m] = n(!1), p = () => {
    m((e) => !e);
  };
  return /* @__PURE__ */ t.jsx(
    r,
    {
      ...s,
      ref: i,
      type: o ? "text" : "password",
      children: /* @__PURE__ */ t.jsx(r.Suffix, { children: /* @__PURE__ */ t.jsx(
        d,
        {
          size: "sm",
          variant: "ghost",
          onClick: p,
          children: /* @__PURE__ */ t.jsx(f, { width: 24, strokeWidth: 1.5, height: 24, name: o ? "EyeEmpty" : "EyeOff" })
        }
      ) })
    }
  );
});
h.displayName = "PasswordInput";
export {
  h as default
};
